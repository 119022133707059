<template>
  <div id="app">
    <header class="header" :class="[show ? 'header--fixed' : '']">
      <div class="around flex relative" >
        <div class="flex-2dan">
          <div style="display: flex; flex-direction: warp; align-items: center" @click="Index">
          <img src="./assets/logo.png" class="logo_img" />
          </div>
          <div class="">
            <ul class="flex items-center nav-title">
              <router-link to="/" tag="li" :class="{'nav-active':navActive == '/'}" class="px-1 py-0.5 font-bold">{{$t("nav.audits")}}</router-link>
              <router-link to="/about" tag="li" :class="{'nav-active':navActive == '/about'}" class="px-1 py-0.5 ml-2 font-bold">{{$t("nav.about")}}</router-link>
            </ul>
          </div>
        </div>
        <div class="flex absolute flex-center header-icon">
          <p class="px-1">
            <svg t="1662019301251" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7534" width="20" height="20"><path d="M510.85 511.04m-447.5 0a447.5 447.5 0 1 0 895 0 447.5 447.5 0 1 0-895 0Z" fill="#26A17B" p-id="7535"></path><path d="M783.54 486.55c0-42.01-102.97-66.63-218.61-72.47v-51.42h161.54V254.48H295.21v108.18h161.55v51.42c-115.63 5.84-218.61 30.47-218.61 72.47 0 41.99 102.97 66.61 218.61 72.45v208.6h108.18V559.01c115.63-5.84 218.6-30.47 218.6-72.46z m-218.61 54.46v-0.22c-17.67 1.08-36.06 1.44-54.09 1.44s-36.42-0.36-54.09-1.44v0.22c-125.5-6.34-200.58-33.94-200.58-54.46 0-20.51 75.07-48.13 200.58-54.47v90.68c17.31 1.08 35.34 1.44 54.09 1.44 19.11 0 37.14-0.36 54.09-1.44v-90.68c125.5 6.34 200.58 33.96 200.58 54.47 0 20.52-75.07 48.12-200.58 54.46z" fill="#FFFFFF" p-id="7536"></path></svg>
          </p>
          <p class="px-1">
            <svg t="1662019271452" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7013" width="21" height="21"><path d="M512 512m-405.333333 0a405.333333 405.333333 0 1 0 810.666666 0 405.333333 405.333333 0 1 0-810.666666 0Z" fill="#FF5722" p-id="7014"></path><path d="M581.546667 337.28h-158.506667v-113.706667h59.306667v85.333334h39.466666v-85.333334h59.733334zM423.04 693.546667h158.506667v113.706666h-59.52v-85.333333h-39.253334v85.333333h-59.733333z" fill="#FFFFFF" p-id="7015"></path><path d="M362.666667 746.666667V289.706667h160.426666c172.16 0 169.386667 103.893333 169.386667 125.44A100.266667 100.266667 0 0 1 627.2 512a97.28 97.28 0 0 1 76.8 100.906667c0 122.24-112.213333 134.4-165.12 134.4z m94.506666-265.813334h69.76c24.106667 0 71.253333-6.186667 71.253334-56.96 0-57.813333-49.066667-58.453333-75.093334-58.453333h-65.92z m0 66.56v123.52h80.64c12.16 0 70.826667-2.346667 70.826667-59.733333a58.88 58.88 0 0 0-67.413333-64z" fill="#FFFFFF" p-id="7016"></path></svg>
          </p>
          <p class="px-1">
            <svg t="1662019236381" class="icon" viewBox="0 0 1029 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4626" width="18" height="18"><path d="M1028.653987 511.98976c0 282.771945-229.222936 511.98976-511.98976 511.98976-282.761705 0-511.98976-229.217816-511.98976-511.98976 0-282.766825 229.228055-511.98976 511.98976-511.98976 282.766825 0 511.98976 229.222936 511.98976 511.98976" fill="#497391" p-id="4627"></path><path d="M289.970521 575.430411l219.602648-452.358312v567.95536zM743.368173 575.430411l-220.380873 115.597048V122.918502zM289.970521 591.97792l219.602648 109.954921v176.810544zM743.368173 591.97792l-220.380873 286.765465v-176.810544z" fill="#FFFFFF" p-id="4628"></path></svg>
          </p>
          <p class="px-1">
            <svg t="1662019597469" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8043" width="20" height="20"><path d="M512.85 511.04m-447.5 0a447.5 447.5 0 1 0 895 0 447.5 447.5 0 1 0-895 0Z" fill="#D80917" p-id="8044"></path><path d="M477.1 787.2c-0.84 0-1.71-0.05-2.55-0.18a18.645 18.645 0 0 1-15.04-12.25L277.69 259.74c-2.31-6.56-0.78-13.86 3.97-18.94s11.96-7.12 18.63-5.23l366.29 102.15c2.37 0.66 4.63 1.8 6.56 3.35l68.87 54.7c7.76 6.15 9.36 17.3 3.64 25.36L492.32 779.31a18.628 18.628 0 0 1-15.22 7.89zM324.8 281.12l157.87 447.25L705 414.01l-52.08-41.37-328.12-91.52z" fill="#FFFFFF" p-id="8045"></path><path d="M477.13 787.2c-0.69 0-1.35-0.04-2.04-0.11-10.23-1.11-17.63-10.31-16.53-20.54l27.42-253.89c1.09-10.27 10.6-17.48 20.54-16.53 10.23 1.11 17.63 10.31 16.53 20.54l-27.42 253.89c-1.02 9.55-9.1 16.64-18.5 16.64z" fill="#FFFFFF" p-id="8046"></path><path d="M504.52 533.31c-4.73 0-9.47-1.78-13.11-5.37-7.32-7.25-7.39-19.05-0.15-26.38L648.3 342.57c7.25-7.32 19.05-7.39 26.37-0.16 7.32 7.25 7.39 19.05 0.15 26.38L517.77 527.77a18.59 18.59 0 0 1-13.25 5.54z" fill="#FFFFFF" p-id="8047"></path><path d="M504.52 533.31c-7.03 0-13.77-4.01-16.93-10.83-4.3-9.34-0.22-20.43 9.1-24.75l225.9-104.28c9.4-4.32 20.43-0.24 24.76 9.12 4.3 9.34 0.22 20.43-9.1 24.75L512.35 531.6a18.857 18.857 0 0 1-7.83 1.71z" fill="#FFFFFF" p-id="8048"></path><path d="M507.21 536.55c-5.46 0-10.85-2.39-14.53-6.99L280.73 265.19c-6.45-8.03-5.15-19.76 2.9-26.2 8.01-6.41 19.79-5.12 26.2 2.9l211.91 264.37c6.45 8.03 5.17 19.76-2.88 26.2a18.563 18.563 0 0 1-11.65 4.09z" fill="#FFFFFF" p-id="8049"></path></svg>
          </p>
        </div>
      </div>
    </header>
    
    <!-- 内容视图 -->
    <router-view></router-view>

    <footer class="footer">
      
      <div class="shell1">
        <div class="contact_us">{{ $t("content.contactUs") }}</div>
        <aside
          class="footer__aside"
          style="display: flex; flex-direction: row; align-items: center"
        >
          <p style="font-size: 16px">Official Email:</p>
          <p>
            <a href="mailto:service@chain-audit.com">{{
              $t("content.contactDetails")
            }}</a>
          </p>
        </aside>
      </div>

      <div style="margin-bottom: 1px; text-align: center">
        <div class="align-center">
          <p>©Copyright 2016 - 2029 crypto audit. All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import api from "@/api/auth"
export default {
  name: "App",
  data() {
    return {
      show: false,
      navActive: "/",
    };
  },
  watch:{
    $route:{
      handler(to,from){
        this.navActive = to.path;
      },
      immediate:true,
    }
  },
  created(){
    let _ = this

  },
  mounted() {
    window.document.body.onscroll = () => {
      if (document.documentElement.scrollTop > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    };
  },
  methods: {
    hello(index) {
      this.spanIdenx = index;
    },
    Index(){
      this.$router.push("/")
    }
  },

};
</script>

<style lang="scss">

body {
  font-family: Poppins, Helvetica, sans-serif;
  color: #191d34;
  margin: auto;
  font-size: 14px;
  line-height: 1.71;
  overflow-x: hidden;
}
.header .around {
  width: 70%;
  margin: 0 auto;
}
:root {
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.nav-title {
  color:#191d34;
  transition: all .3s ease;
}
.flex-2dan {
  display: flex;
}
.nav-title li {
  cursor: pointer;
}

.nav-active {
  color:#377df5 !important;
  border-bottom: 3px solid #377df5
}

a {
  text-decoration: none;
  color: #191d34;
}

ul {
  overflow-wrap: break-word;
}

li {
  list-style-type: none;
}
.logo_img , .header-icon{
  margin-left: 10px;
  height: 50px;
}
.header-icon p{
  margin: 0;
  display: flex;
  align-items:center ;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 100;

  .header-ul {
    display: flex;
    margin-left: -50px;
    li {
      padding-left: 10px;
    }
  }
  .btn-more {
    color: #191d34;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.057em;
    text-decoration: none;
  }

  .btn-more:before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    top: -6px;
    right: -4px;
    border-top: 2px solid #377df5;
    border-right: 2px solid #377df5;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;

    a {
      font-size: 10px;
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      -webkit-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
}
.header--fixed {
  position: fixed;
  top: 0;
  background: hsla(0, 0%, 100%, 0.97);
  -webkit-box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  padding: 10px 0 7px;
}
.contact_us {
  font-size: 24px;
}
.shell1 {
  align-items: center;
  padding: 12px 15px;
  
  h1 {
    text-align: center;
  }
  p {
    font-size: 15px;
    font-weight: 200;
  }
}

.shell {
  padding: 12px 2px;
  a {
    font-weight: 400;
    font-size: 18px;
  }
  p {
    font-size: 15px;
    font-weight: 200;
  }
}

.footer {
  padding: 20px 0 0px;

  .footer__aside {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
  }
}
.header-icon {
  right: 1rem;
}
@media screen and (max-width:1240px) {
 .header .around {
   width: 100%;
 }
}
@media screen and (max-width:768px) {
  .contact_us {
    font-size: 16px;
  }
  .logo_img,.header-icon {
    height: 38px;
  }
  .flex-2dan {
    flex-direction: column;
  }
}
</style>
