import Vue from 'vue'
import App from './App.vue'
import './assets/css/iconfont.css'
import EasySlider from 'vue-easy-slider'
import AOS from "aos"
import "../node_modules/aos/dist/aos.css"
import i18n from './i18n/index'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
import store from './store'
import erc from '@/wallet/erc.js'
import vmodal from 'vue-js-modal'
import router from "@/routes/index"
import "@/common/style.css"

Vue.prototype.$layer = layer(Vue);

Vue.use(AOS)
AOS.init()
Vue.use(EasySlider)
Vue.use(vmodal)
Vue.config.productionTip = false
Vue.prototype.$wallet = {
  erc
}

new Vue({
  store,
  i18n,
  render: h => h(App),
  router
}).$mount('#app')
