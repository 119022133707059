import request from '@/common/request.js';
import store  from "@/store/index.js"
export default {
  getPlatform(callback = null , page = 15 ) {
    request.get('front/platforms' , { page: page  } ).then((res)=>{
      if(res.status == 200){
         store.commit('setInit' ,res.data)
         if(callback && callback != undefined){
           callback(res.data)
         }
      }
   })
  },
  postAddress(address){
    return request.post('front/address' ,address)
  },
  postAudit(data){
    return request.post('front/audit' ,data)
  },
  GetInit( callback ){
    let init = store.state.init;
    if(!init || init == undefined){
        request.get('front/init' ,{}).then((res)=>{
           if(res.status == 200){
              store.commit('setInit' ,res.data)
              if(callback && callback != undefined){
                callback(res.data)
              }
           }
        })
    }else{
     
      if(callback && callback != undefined){
        callback(init)
      }
    }
  }
}