import axios from 'axios'
import Qs from 'qs'
const $serverUrl = '/apis/';

const axiosInstance = axios.create({
    timeout: 10000
});
export default {
    header: {

    },
    getHeader:function(){
        return this.header;
    },
    setHeader:function(key ,value){
        this.header[key] =value;
    },
    getServer:function(){
        return $serverUrl;
    },
    get: function(api, params={}) {
        return this.request(api ,'get' ,params)
    },
    post: function(api, data={}) {
        return this.request(api ,'post' ,data)
    },
    request:function (api ,method='get' ,params={} ){
        let url =api.indexOf('http')===0 ? api : $serverUrl+api;
        let headers=this.getHeader();
        console.log(url)
        headers['Content-Type']= 'application/x-www-form-urlencoded';
        let config={
            url:url,
            method: method.toLowerCase(),
            headers:headers
        }
        if(method=='get'){
            let p = typeof(params) =='object' ? JSON.stringify(params) : params;
            config.params= "p="+ p
        }
        if(method=='post'){
            let p = typeof(params) =='object' ? JSON.stringify(params) : params;
            config.data="p="+ p
        }
        return new Promise(resolve => {
            console.log(config)
            axiosInstance.request(config).then(res=>{
                if(res.status!=200){

                }else{
                    resolve(res.data)
                }
            }).catch(e=>{
              console.log('request error:',e)
            })
        })

    },
    upload:function(api,params={}){
        let url =api.indexOf('http')===0 ? api : $serverUrl+api;
        let headers =this.getHeader();
        headers['Content-Type'] ='multipart/form-data'
        let config={
            headers:headers
        }
        return new Promise(resolve => {
            axiosInstance.post(url ,params ,config).then(res=>{
                console.log(res)
                if(res.status!=200){

                }else{
                    resolve(res.data)
                }
            })
        })
    }
}
