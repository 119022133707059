const en = {
    language: {
        zh: '中文',
        en: 'English',
    },
    nav: {
      update: 'Get updates',
      audits: 'Audits',
      about: 'About',
    },
    content: {
      blockchain: 'Crypto Asset Security Verification ',
      audit: 'Request an Audit',
      chainSecurityIntroduction: 'Crypto Audit  is led by security experts from the renowned university ETH Zurich. We are trusted by 85+ blockchain companies and established corporations with security audits and services.',
      secured: 'About us',
      securedIntroduction: '\tCryptoAudit is led by security experts from the prestigious university ETH Zurich. We are trusted by over 85 established companies with security audits and services from cryptocurrency exchanges and multinational organizations such as banks and industrial consortia.<br \>',
       securedIntroduction1: 'We are excited to bring smart contract code review and verification of crypto assets back to the DeFi market under the established CryptoAudit brand. Our relocation from PwC enables our team to Capturing opportunities beyond traditionally regulated markets with the flexibility needed to adapt and grow this emerging market. PwC and CryptoAudit continue to collaborate to help build trust in the growing cryptocurrency ecosystem.',
      customerReviews: 'What our customers say',
      evaluation1: 'The Chain Auditteam went the extra mile to ensure the HOPR token and distributor contracts are secure. They even identified and resolved a complex issue in the ERC777 implementation by OpenZeppelin that used by many projects in the space.',
      evaluation2: 'With their thorough and high quality audits, Chain Audithas been one of Kyber Network’s primary auditors for years now. We look forward to continuing our partnership with them for many more years to come as we keep growing the frontiers of blockchain.',
      evaluation3: 'Chain Auditwent above and beyond our expectations when it came to the audit of our Liquidations 2.0 upgrade of the MakerDAO Protocol. They were the perfect partner to support the biggest upgrade of the protocol since its launch.',
      evaluation4: 'The Chain Auditteam went the extra mile to ensure the HOPR token and distributor contracts are secure. They even identified and resolved a complex issue in the ERC777 implementation by OpenZeppelin that used by many projects in the space.',
      clientName1: 'Mona El Isa (CEO)',
      clientName2: 'Loi Luu (CEO)',
      clientName3: 'Wouter Kampmann (Head of Engineering)',
      clientName4: 'Dr Sebastian Bürgel, HOPR Founder',
      swissExpertise: 'Our mission',
      swissExpertiseIntroduction: 'Our mission is to build trust in the cryptocurrency ecosystem, allowing this emerging technology to reach its potential and trust in established organizations, governments and blockchain companies.',
      learnMore: 'Learn more',
      globalExperience: 'Global Experience',
      globalExperienceIntroduction: 'Our team is led by former Big 4 professionals, with a global mindset and long-term business vision.',
      blog: 'From Our Blog',
      blogTime: 'Jan 15, 2019',
      blogContent: 'Constantinople enables new Reentrancy Attack',
      contactUs: 'Contact Us',
      platUs: "Platform Us",
      contactDetails: 'service@chain-audit.com'
    },
    audit: {
      cooperationPlatform: 'Cooperation Platform',
      walletAddress: 'wallet address',
      email: 'email',
      project: 'Complexity of the project',
      submit: 'Submit',
      wallet: 'Unable to get wallet address!',
      emailAddress: 'Please fill in your email address!',
      emailAddressIsIncorrect: 'Email address is incorrect!',
      platform: 'Please choose a cooperation platform!'
    }
}

export default en;
