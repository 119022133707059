import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
	state: {
    trcAddress:{
      address:'',
      balance:0,
      gas:0,
      init:{},
    },
    userinfo:{},
	},
	mutations: {
		setTrcAddress(state, address){
			state.trcAddress = address;
		},
    setInit(state, init){
      state.init = init
    },
    setUser(state, user){
      state.userinfo = user
    }
	},
	actions: {

	}
})

export default store
