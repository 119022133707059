import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: '/w',
        name: 'Index',
        meta: {
          index: 1
        },
        component: () => import(/* webpackChunkName: "index" */ '../views/n-index.vue'),
  },
  {
      path: '/',
      name: 'Index',
      meta: {
        index: 1
      },
      component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
  },
    {
        path: '/about',
        name: 'About',
        meta: {
          index: 2
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    },
];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router